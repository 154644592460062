<template>
    <div class="anchor-container">
      <search-card />
      <search-result style="margin-top:30px" />
      <table-card style="margin-top:30px" />
      <!-- <keep-alive>
      <router-view></router-view>
      </keep-alive> -->
    </div>
</template>

<script>

import { 
searchCard,
tableCard,
searchResult
} from '@/components/anchor/index'
export default {
  name: "anchor",
  components: {
    searchCard,
    tableCard,
    searchResult
  },
  data() {
    return {

    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.anchor-container {
  width: 100%;

}
</style>
